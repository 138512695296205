<template>
  <v-app>
    <v-app-bar
      app
      color="pink lighten-3"
    >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list
          nav
          dense
        >
          <v-list-item
            @click="$router.push('/')"
          >
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Kalender</v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="$router.push('/account')"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Benutzerkonto</v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="$router.push('/about')"
          >
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Über / Impressum</v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="$router.push('/poweredBy')"
          >
            <v-list-item-icon>
              <v-icon>mdi-battery-charging</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Powered by</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-toolbar-title>Omas Besuchskalender</v-toolbar-title>

      <v-spacer></v-spacer>

      {{ state.authUserName }}
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-overlay
      :value="state.asyncJobCount > 0"
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
// import logger from '@/logger';
import state from '@/state';

export default {
  name: 'App',

  data: () => ({
    state,
  }),

  mounted() {
    state.runAsyncInForeground(async () => {
      await state.getAuthUser();
    });
  }
};
</script>
