<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert
          type="error"
        >
          <p><strong>FEHLER:</strong> Du bist nicht angemeldet!</p>
          <p>Bitte benutze den Link, den Du in Deiner Einladung bekommen hast!</p>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
};
</script>
