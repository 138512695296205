let nextJsonRpcId = 1;

async function performJsonRpc(method, params) {
  const id = nextJsonRpcId;
  nextJsonRpcId += 1;

  const request = {
    jsonrpc: '2.0',
    id,
    method,
    params,
  };

  const httpRequestBody = JSON.stringify(request);

  const httpResponse = await fetch('/api/v1/jsonrpc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: httpRequestBody,
  });

  if (!httpResponse.ok) {
    throw new Error(`Invalid response from server`);
  }

  const httpResponseBody = await httpResponse.json();

  const response = httpResponseBody;
  if (response.error) {
    throw new Error(`RPC request failed: ${response.error.message}`);
  }

  return response.result;
}

const api = {
  async getAuthUser(token) {
    return await performJsonRpc('getAuthUser', {
      token,
    });
  },

  async updateUser(token, name) {
    return await performJsonRpc('updateUser', {
      token,
      name,
    });
  },

  async inviteUser(token, name) {
    return await performJsonRpc('inviteUser', {
      token,
      name,
    });
  },

  async getInvitations(token) {
    return await performJsonRpc('getInvitations', {
      token,
    });
  },

  async getInvitation(token, invitation) {
    return await performJsonRpc('getInvitation', {
      token,
      invitation,
    });
  },

  async getAppointments(token) {
    return await performJsonRpc('getAppointments', {
      token,
    });
  },

  async createAppointment(token, start, end) {
    return await performJsonRpc('createAppointment', {
      token,
      start,
      end,
    });
  },

  async updateAppointment(token, id, start, end) {
    return await performJsonRpc('updateAppointment', {
      token,
      id,
      start,
      end,
    });
  },

  async deleteAppointment(token, id) {
    return await performJsonRpc('deleteAppointment', {
      token,
      id,
    });
  },
};

export default api;
