<template>
  <v-container>
    <v-row>
      <v-col class="headline">
        Benutzerkonto
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-title>Mein Benutzer</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col
                class="text-right align-self-center"
                cols="3"
              >
                Name
              </v-col>
              <v-col
                cols="9"
              >
                <v-text-field
                  v-model="name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mx-2">
            <v-row>
              <v-col>
                <v-btn
                  @click="updateUser"
                  block
                  color="primary"
                >
                  Speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-title>Meine Einladungen</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row
              v-for="invitation in invitations"
              :key="invitation.invitation"
            >
              <v-col
                class="align-self-center"
                cols="5"
                md="8"
              >
                {{ invitation.name }}
              </v-col>
              <v-col
                cols="7"
                md="4"
              >
                <v-btn
                  @click="$router.push(invitation.to)"
                  color="blue"
                  block
                >
                  Zur Einladung
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="invitations.length === 0"
            >
              <v-col
                class="font-italic"
              >
                Noch keine Einladungen erzeugt
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mx-2">
            <v-row>
              <v-col
                cols="6"
                md="8"
              >
                <v-text-field
                  v-model="invitingUserName"
                  label="Name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                md="4"
                class="align-self-center"
              >
                <v-btn
                  @click="inviteUser"
                  block
                  color="green"
                  :disabled="!invitingUserName"
                >
                  Einladen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/api';
import logger from '@/logger';
import state from '@/state';

export default {
  data: () => ({
    name: '',

    invitations: [],
    invitingUserName: '',
  }),

  mounted() {
    state.runAsyncInForeground(async () => {
      const user = await state.ensureAuthUser();

      this.name = user.name;

      await this.updateInvitations();
    });
  },

  methods: {
    async updateInvitations() {
      const invitations = await api.getInvitations(state.token);

      this.invitations = invitations.map(invitation => {
        return {
          ...invitation,
          to: {
            name: 'InviteWithParam',
            params: {
              invitation: invitation.invitation,
            },
          },
        };
      });
    },

    updateUser() {
      state.runAsyncInForeground(async () => {
        await api.updateUser(state.token, this.name);

        await state.getAuthUser();
      });
    },

    inviteUser() {
      state.runAsyncInForeground(async () => {
        const { invitation } = await api.inviteUser(state.token, this.invitingUserName);

        logger.debug(invitation);

        this.$router.push({
          name: 'InviteWithParam',
          params: {
            invitation,
          },
        });
      });
    },
  },
};
</script>

<style>

</style>