<template>
  <v-container fill-height style="max-width: 360px">
    <v-row>
      <v-col>
        <v-btn
          @click="$router.push('/createAppointment')"
          color="green"
          large
          block
          dark
        >
          <v-icon>mdi-plus</v-icon>
          Neuen Besuchstermin anlegen
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col
        v-if="days.length > 0"
      >
        <v-timeline
          class="mx-auto"
          style="max-width: 360px"
          dense
        >
          <div
            v-for="day in days"
            :key="day.id"
          >
            <v-timeline-item
              class="mt-4 grey--text"
              hide-dot
            >
              {{ day.title }}
            </v-timeline-item>

            <v-timeline-item
              v-for="appointment in day.appointments"
              :key="appointment.id"
              fill-dot
              small
            >
            <v-card>
              <v-card-text>
                <v-row class="">
                  <v-col cols="9">
                    <span class="font-weight-bold">{{ appointment.name }}</span><br>
                    <span class="font-italic">{{ appointment.localStartTime}} - {{ appointment.localEndTime}}</span>
                  </v-col>
                  <v-col cols="3" class="align-self-center">
                    <v-btn
                      v-if="appointment.canUpdate"
                      @click="askToDeleteAppointment(appointment)"
                      icon
                      color="red"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
            </v-timeline-item>
          </div>
        </v-timeline>
      </v-col>
      <v-col
        v-if="days.length === 0"
        class="text-center font-italic"
      >
        Keine Besuchstermine gefunden
      </v-col>
    </v-row>

    <v-dialog
      v-model="deleteAppointmentConfirmDialogVisible"
      width="360"
    >
      <v-card>
        <v-card-title>Sicherheitsabfrage</v-card-title>
        <v-card-text>
          Soll dieser Termin wirklich gelöscht werden? Das kann nicht rückgängig gemacht werden!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteAppointmentConfirmDialogVisible = false"
          >
            Nein
          </v-btn>
          <v-btn
            color="red"
            @click="deleteAppointmentConfirmed"
          >
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from '@/api';
import logger from '@/logger';
import state from '@/state';
import { formatLocalDateId, formatLocalTime } from '@/utils';

export default {
  data: () => ({
    state,

    days: [],

    deleteAppointmentConfirmDialogCallback: null,
    deleteAppointmentConfirmDialogVisible: false,
  }),

  watch: {
    'state.lastBackendUpdateAt'() {
      logger.debug('backend updated');

      state.runAsyncInBackground(async () => {
        await this.updateDays();
      });
    },
  },

  mounted() {
    state.runAsyncInForeground(async () => {
      await state.ensureAuthUser();

      await this.updateDays();
    });
  },

  methods: {
    // handleUpdateInterval() {

    // },

    async updateDays() {
      const appointments = await api.getAppointments(state.token);

      logger.debug(appointments);

      const localeDateStringOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

      const days = [];
      for (const appointment of appointments) {
        const startDate = new Date(appointment.start);
        const endDate = new Date(appointment.end);

        const localDateId = formatLocalDateId(startDate);
        const localStartTime = formatLocalTime(startDate);
        const localEndTime = formatLocalTime(endDate);

        let day = days.find(day => day.id === localDateId);
        if (!day) {
          day = {
            id: localDateId,
            title: startDate.toLocaleDateString(undefined, localeDateStringOptions),
            appointments: [],
          };
          days.push(day);
        }

        day.appointments.push({
          ...appointment,
          localStartTime,
          localEndTime,
        });
      }

      this.days = days;
    },

    askToDeleteAppointment(appointment) {
      logger.debug(appointment);

      this.deleteAppointmentConfirmDialogCallback = () => {
        state.runAsyncInForeground(async () => {
          await api.deleteAppointment(state.token, appointment.id);
        });
      };

      this.deleteAppointmentConfirmDialogVisible = true;
    },

    deleteAppointmentConfirmed() {
      this.deleteAppointmentConfirmDialogCallback();
      this.deleteAppointmentConfirmDialogVisible = false;
    },
  },
};
</script>
