<template>
  <v-container>
    <v-row>
      <v-col>
        <p>Hallo {{ invitation.invitedName }}!</p>
        <p>
          Du bist von {{ invitation.inviterName }} zu Oma Kuckucks Besuchskalender eingeladen worden.
          Willkommen!
        </p>
        <p>
          Da Oma im Moment ja nur einzelne Personen gleichzeitig als Besuch empfangen darf, soll
          dieser Kalender bei der Terminabsprache helfen.
        </p>
        <p>
          Der Link, den Du gerade geöffnet hast, ist alles, was Du brauchst. Keine EMail-Adresse,
          kein Passwort! Speichere diesen Link einfach in Deinen Lesezeichen / Favoriten oder bei
          Smartphones und Tablets direkt als Icon auf dem Startbildschirm. Dann ist der
          Besuchskalender immer nur einen Klick entfernt.
        </p>
        <p>
          Über das <v-icon>mdi-menu</v-icon>-Symbol in der oben linken Ecke kommst du zu den
          anderen Bereichen:
        </p>
        <ul class="my-4">
          <li>
            <strong>Kalender:</strong> dort können die bereits angelegten Termine eingesehen und
            neue erstellt werden
          </li>
          <li>
            <strong>Benutzerkonto:</strong> dort kannst Du Deinen angezeigten Namen ändern und
            andere Familienmitglieder zu diesem Kalender einladen, wenn sie möchten
          </li>
          <li>
            <strong>Über / Impressum und Powered by:</strong> der übliche, rechtlich notwendige Krimskrams...
          </li>
        </ul>
        <p>
          Wenn Du weitere Fragen hast, kannst Du sie gerne stellen. Die EMail-Adresse steht im
          Impressum.
        </p>
        <p>
          Bleibt gesund und habt netten Menschen um Euch!
        </p>
        <p class="ml-8">
          Antje &amp; Daniel
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    invitation: Object,
  },
};
</script>

<style>

</style>