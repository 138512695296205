<template>
  <v-container>
    <v-row>
      <v-col class="text-h5">
        Powered by
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h6">
        Software packages
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="pkg in packages"
            :key="pkg.id"
          >
            <v-expansion-panel-header>
              {{ pkg.name }} <v-spacer></v-spacer> {{ pkg.version }} &bull; {{ pkg.licenses }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span class="font-weight-bold">Name:</span> {{ pkg.name }}<br>
              <span class="font-weight-bold">Version:</span> {{ pkg.version }}<br>
              <div v-if="pkg.publisher"><span class="font-weight-bold">Publisher:</span> {{ pkg.publisher }}<br></div>
              <span class="font-weight-bold">Licenses:</span> {{ pkg.licenses }}<br>
              <span class="font-weight-bold">URL:</span> <a :href="pkg.repository" target="_blank">{{ pkg.repository }}</a><br>
              <span class="font-weight-bold">License:</span><br><pre class="dw-license">{{ pkg.licenseText }}</pre>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-h6">
        Other assets
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="asset in assets"
            :key="asset.id"
          >
            <v-expansion-panel-header>
              {{ asset.name }} <v-spacer></v-spacer> {{ asset.version }} &bull; {{ asset.licenses }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span class="font-weight-bold">Name:</span> {{ asset.name }}<br>
              <span class="font-weight-bold">Version:</span> {{ asset.version }}<br>
              <div v-if="asset.publisher"><span class="font-weight-bold">Publisher:</span> {{ asset.publisher }}<br></div>
              <span class="font-weight-bold">Licenses:</span> {{ asset.licenses }}<br>
              <span class="font-weight-bold">URL:</span> <a :href="asset.url">{{ asset.url }}</a><br>
              <div v-if="asset.attributionHtml"><span class="font-weight-bold">Attribution:</span> <span v-html="asset.attributionHtml"></span></div>
              <div v-if="asset.licenseText"><span class="font-weight-bold">License:</span><br><pre class="dw-license">{{ asset.licenseText }}</pre></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import packages from '@/packages.json';

const assets = [{
  id: '1',
  name: 'Calendar Icon',
  version: '???',
  publisher: 'Freepik',
  licenses: 'Flaticon License',
  url: 'https://www.flaticon.com/free-icon/calendar_3176344?term=calendar&related_id=3176344',
  attributionHtml: '<div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>',
}];

export default {
  data: () => ({
    packages,
    assets,
  }),
};
</script>

<style scoped>
.dw-license {
  font-size: 12px;
}
</style>