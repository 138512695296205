<template>
  <div>
    <v-timeline
      v-if="appointments.length > 0"
      dense
    >
      <v-timeline-item
        v-for="appointment in appointments"
        :key="appointment.id"
        fill-dot
        small
      >
        <v-card>
          <v-card-text>
            <v-row class="">
              <v-col>
                <span class="font-weight-bold">{{ appointment.name }}</span><br>
                <span class="font-italic">{{ appointment.localStartTime}} - {{ appointment.localEndTime}}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <div
      v-if="appointments.length === 0"
      class="text-center font-italic"
    >
      Keine Besuchstermine gefunden
    </div>
  </div>
</template>

<script>
export default {
  props: {
    appointments: Array,
  },
};
</script>

<style>

</style>