import Vue from 'vue';

import api from '@/api';
import logger from '@/logger';
import router from '@/router';

const persistentKey = 'de.wippermaenner.omakalender.state';

const persistentFields = [
  'token',
];

const state = new Vue({
  data: () => ({
    // persistent
    token: null,

    // volatile
    asyncJobCount: 0,
    lastBackendUpdateAt: 0,

    authUserName: null,
  }),

  methods: {
    store() {
      const obj = {};
      for (const field of persistentFields) {
        obj [field] = this [field];
      }
      localStorage.setItem(persistentKey, JSON.stringify(obj));
    },

    runAsyncInBackground(fn) {
      async function runner() {
        return await fn();
      }

      return runner().then(null, err => {
        logger.error(err);
      });
    },

    runAsyncInForeground(fn) {
      async function runner() {
        this.asyncJobCount += 1;
        try {
          return await fn();
        } finally {
          this.asyncJobCount -= 1;
        }
      }

      return runner.call(this).then(null, err => {
        logger.error(err);
      });
    },

    async getAuthUser() {
      try {
        const user = await api.getAuthUser(state.token);
        if (!user) {
          throw new Error(`User not authorized`);
        }

        state.authUserName = user.name;

        return user;
      } catch (err) {
        state.authUserName = null;

        throw err;
      }
    },

    async ensureAuthUser() {
      try {
        return await this.getAuthUser();
      } catch (err) {
        router.replace('/notAuthorized');
      }
    },
  },
});

try {
  const objString = localStorage.getItem(persistentKey);
  if (objString) {
    const obj = JSON.parse(objString);
    for (const field of persistentFields) {
      if (field in obj) {
        state [field] = obj [field];
      }
    }
  }
} catch (err) {
  logger.err(err);
}

const socket = io();

socket.on('backendUpdated', () => {
  logger.debug('received socket.io notification');
  state.lastBackendUpdateAt = Date.now();
});

// FIXME(daniel): only for dev purposes
window.dwState = state;

export default state;
