<template>
  <v-container style="max-width: 600px">
    <v-stepper
      v-model="step"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          Datum
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          Startzeit
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 3"
          step="3"
        >
          Endzeit
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          class="pa-1"
          step="1"
        >
          <v-card>
            <v-card-title>Datum auswählen</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-date-picker
                    v-model="date"
                    :events="checkAppointmentsOnDate"
                    :first-day-of-week="1"
                    locale="de-DE"
                    :min="today"
                  >
                  </v-date-picker>
                </v-col>
                <v-col>
                  <DayTimeline
                    :appointments="appointmentsOnDate"
                  ></DayTimeline>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="mx-2">
              <v-btn
                @click="$router.back()"
              >
                Zurück
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="selectDate"
                color="green"

              >Weiter</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content
          class="pa-1"
          step="2"
        >
          <v-card>
            <v-card-title>Startzeit auswählen</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-select
                    v-model="startTime"
                    :items="startTimes"
                  ></v-select>
                </v-col>
                <v-col>
                  <DayTimeline
                    :appointments="appointmentsOnDate"
                  ></DayTimeline>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                @click="step = 1"
              >
                Zurück
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="selectStartTime"
                color="green"
                :disabled="!startTime"
              >Weiter</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content
          class="pa-1"
          step="3"
        >
          <v-card>
            <v-card-title>Endzeit auswählen</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-select
                    v-model="endTime"
                    :items="endTimes"
                  ></v-select>
                </v-col>
                <v-col>
                  <DayTimeline
                    :appointments="appointmentsOnDate"
                  ></DayTimeline>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                @click="step = 2"
              >
                Zurück
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="createAppointment"
                color="green"
                :disabled="!endTimeIsValid"
              >Besuchstermin anlegen</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import api from '@/api';
import logger from '@/logger';
import state from '@/state';
import { formatLocalDateId, formatLocalTime } from '@/utils';

import DayTimeline from '@/components/DayTimeline.vue';

const validTimes = [
  '08:00', '08:30',
  '09:00', '09:30',
  '10:00', '10:30',
  '11:00', '11:30',
  '12:00', '12:30',
  '13:00', '13:30',
  '14:00', '14:30',
  '15:00', '15:30',
  '16:00', '16:30',
  '17:00', '17:30',
  '18:00', '18:30',
  '19:00', '19:30',
  '20:00',
];

export default {
  data: () => ({
    appointments: [],

    step: 1,

    today: null,
    date: null,
    startTime: null,
    endTime: null,
  }),

  computed: {
    appointmentsOnDate() {
      return this.appointments.filter(appointment => appointment.localDateId === this.date);
    },

    startTimes() {
      if (this.date) {
        const appointments = this.appointmentsOnDate;

        return validTimes.slice(0, validTimes.length - 1).filter(time => {
          return !appointments.some(appointment => (time >= appointment.localStartTime) && (time < appointment.localEndTime));
        });
      } else {
        return [];
      }
    },

    endTimes() {
      if (this.date && this.startTime) {
        const appointments = this.appointmentsOnDate.filter(appointment => appointment.localStartTime >= this.startTime);
        const maxTime = (appointments.length > 0) ? appointments [0].localStartTime : '24:00';

        return validTimes.slice(1).filter(time => {
          if (time <= this.startTime) {
            return false;
          } else {
            return (time <= maxTime);
          }
        });
      } else {
        return [];
      }
    },

    endTimeIsValid() {
      let result = false;
      if (this.date && this.startTime && this.endTime) {
        const start = new Date(`${this.date}T${this.startTime}`).toISOString();
        const end = new Date(`${this.date}T${this.endTime}`).toISOString();
        const now = new Date().toISOString();

        result = (start < end) && (end > now);
      }
      return result;
    },
  },

  components: {
    DayTimeline,
  },

  mounted() {
    state.runAsyncInForeground(async () => {
      const appointments = await api.getAppointments(state.token);

      logger.debug(appointments);

      this.appointments = appointments.map(appointment => {
        const startDate = new Date(appointment.start);
        const endDate = new Date(appointment.end);
        const localDateId = formatLocalDateId(startDate);
        const localStartTime = formatLocalTime(startDate);
        const localEndTime = formatLocalTime(endDate);

        return {
          ...appointment,
          localDateId,
          localStartTime,
          localEndTime,
        };
      });

      this.today = formatLocalDateId(new Date());
      this.date = this.today;
    });
  },

  methods: {
    checkAppointmentsOnDate(date) {
      return this.appointments.some(appointment => appointment.localDateId === date);
    },

    selectDate() {
      this.startTime = this.startTimes [0];
      this.step = 2;
    },

    selectStartTime() {
      this.endTime = this.endTimes [0];
      this.step = 3;
    },

    createAppointment() {
      state.runAsyncInForeground(async () => {
        const start = new Date(`${this.date}T${this.startTime}`).toISOString();
        const end = new Date(`${this.date}T${this.endTime}`).toISOString();

        const result = await api.createAppointment(state.token, start, end);

        logger.debug(result);

        this.$router.back();
      });
    }
  },
};
</script>

<style>

</style>