export function formatLocalDateId(date) {
  function padZero(value, length) {
    return value.toString().padStart(length, '0');
  }

  return `${padZero(date.getFullYear(), 4)}-${padZero(date.getMonth() + 1, 2)}-${padZero(date.getDate(), 2)}`;
}

export function formatLocalTime(date) {
  function padZero(value, length) {
    return value.toString().padStart(length, '0');
  }

  return `${padZero(date.getHours(), 2)}:${padZero(date.getMinutes(), 2)}`;
}

