import Vue from 'vue';
import VueRouter from 'vue-router';

// import logger from '@/logger';
// import state from '@/state';

import About from '@/views/About.vue';
import Account from '@/views/Account.vue';
import CreateAppointment from '@/views/CreateAppointment.vue';
import Home from '@/views/Home.vue';
import Invite from '@/views/Invite.vue';
import NotAuthorized from '@/views/NotAuthorized.vue';
import PoweredBy from '@/views/PoweredBy.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/createAppointment',
    name: 'CreateAppointment',
    component: CreateAppointment
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/invite/:invitation',
    name: 'InviteWithParam',
    component: Invite
  },
  {
    path: '/invite',
    name: 'Invite',
    component: Invite
  },
  {
    path: '/notAuthorized',
    name: 'NotAuthorized',
    component: NotAuthorized
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/poweredBy',
    name: 'PoweredBy',
    component: PoweredBy,
  },
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
