<template>
  <v-container>
    <v-row v-if="phase === 'done'">
      <v-col>
        <Invitation
          :invitation="invitation"
        ></Invitation>
      </v-col>
    </v-row>

    <v-row v-if="phase === 'preview'">
      <v-col>
        <p>Schicke diesen Link an {{ invitation.invitedName }}:</p>

        <p><a :href="url">{{ url }}</a></p>

        <p>
          <v-btn
            @click="sendMail"
          >
            EMail mit der Einladung verfassen
          </v-btn>
        </p>

        <p>Wenn er/sie diesen Link öffnet, wird folgendes angezeigt:</p>

        <v-sheet
          elevation="2"
        >
          <Invitation
            :invitation="invitation"
          ></Invitation>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row v-if="phase === 'error'">
      <v-col>
        <v-alert
          type="error"
        >
          Ein Fehler ist aufgetreten!
        </v-alert>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import api from '@/api';
import logger from '@/logger';
import state from '@/state';

import Invitation from '@/components/Invitation.vue';

export default {
  data: () => ({
    phase: 'loading',

    invitation: null,
    url: null,
  }),

  components: {
    Invitation,
  },

  mounted() {
    state.runAsyncInForeground(async () => {
      try {
        const { invitation } = this.$route.params;

        if (invitation) {
          const result = await api.getInvitation(state.token, invitation);

          if (!state.token) {
            state.token = result.token;
            state.authUserName = result.invitedName;
            state.store();
          }

          this.invitation = result;
          this.url = document.location.toString();

          if (state.token === result.token) {
            this.phase = 'done';
          } else {
            this.phase = 'preview';
          }
        }
      } catch (err) {
        logger.error(err);

        this.phase = 'error';
      }
    });
  },

  methods: {
    sendMail() {
      const { invitedName, inviterName } = this.invitation;
      const url = document.location.toString();

      const subject = `Einladung zu Oma Kuckucks Besuchskalender`;
      const encodedSubject = encodeURIComponent(subject);
      const body = `Hallo ${invitedName}!\n\nIch lade Dich zu Oma Kuckucks Besuchskalender ein. Bitte klicke auf den folgenden Link für weitere Informationen:\n\n${url}\n\nBis bald mal,\n    ${inviterName}`;
      const encodedBody = encodeURIComponent(body);

      const mailToUrl = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
      document.location.replace(mailToUrl);
    },
  },
};
</script>

<style>

</style>